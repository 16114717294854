<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        a) For the three Lewis structures shown below, determine the formal charge of each of the
        three atoms (S, C, and N).
      </p>
      <p>
        <v-img
          class="mb-8"
          style="width: 550px"
          src="@/assignments/assets/img/SCN-Lewis-Structures.png"
        />
      </p>
      <v-simple-table class="mb-8" style="width: 400px">
        <thead>
          <tr>
            <th style="font-size: 16px">Atom</th>
            <th style="font-size: 16px">Structure 1</th>
            <th style="font-size: 16px">Structure 2</th>
            <th style="font-size: 16px">Structure 3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{S}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Sstruct1"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Sstruct2"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Sstruct3"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{C}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Cstruct1"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Cstruct2"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Cstruct3"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{N}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Nstruct1"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Nstruct2"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.Nstruct3"
                class="pb-4 pt-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p>
        b) Which of the three structures listed above is the best Lewis structure for this molecule?
      </p>
      <v-radio-group v-model="inputs.bestStruct" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question208',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Sstruct1: null,
        Sstruct2: null,
        Sstruct3: null,
        Cstruct1: null,
        Cstruct2: null,
        Cstruct3: null,
        Nstruct1: null,
        Nstruct2: null,
        Nstruct3: null,
        bestStruct: null,
      },
      options1: [
        {expression: '$\\text{Structure 1}$', value: 'struct1'},
        {expression: '$\\text{Structure 2}$', value: 'struct2'},
        {expression: '$\\text{Structure 3}$', value: 'struct3'},
      ],
    };
  },
};
</script>
